import { useEffect, useState, type ReactNode } from "react";
import { useStore } from "@nanostores/react";
import type { CartWithItems } from "server/database/schema";
import { cartStore } from "../../store";
import type { ProductId } from "shared";
import { addItem } from "../../store";
import { getLocaleUrl } from "shared";
import { ShoppingCart } from "lucide-react";
import clsx from "clsx";
import type { Locale } from "shared/i18n";

export const Cart = ({
  serverCart,
  children,
  className,
  onlyIcon,
  locale,
  viewCart,
}: {
  onlyIcon?: boolean;
  serverCart: CartWithItems | undefined;
  children: ReactNode;
  className?: string;
  locale: Locale;
  viewCart: string;
}) => {
  const cart = useStore(cartStore) || serverCart;
  useEffect(() => {
    if (serverCart) cartStore.set(cart);
  }, []);

  if (!cart?.items.length) return children;
  const items = cart.items.reduce((acc, item) => acc + item.quantity, 0);

  return (
    <button
      onClick={() => (window.location.href = getLocaleUrl(locale, "cart"))}
      className={clsx("relative", className)}
    >
      {onlyIcon ? (
        <>
          <ShoppingCart />
          <span className="absolute -bottom-1 -right-1 flex aspect-square h-5 w-5 items-center justify-center rounded-full border bg-white text-sm text-black">
            {items}
          </span>
        </>
      ) : (
        <span>
          {viewCart} ({items})
        </span>
      )}
    </button>
  );
};

export const AddToCart = ({
  id,
  className,
  children,
  locale,
  addedToCart,
}: {
  id: ProductId;
  className?: string;
  children: ReactNode;
  locale: Locale;
  addedToCart: string;
}) => {
  const cart = useStore(cartStore);
  const [isAdded, setIsAdded] = useState(false);
  useEffect(() => {
    if (!cart?.items.length) return;
    setIsAdded(cart.items.some((item) => item.productId === id));
  }, [cart?.items.length]);
  return (
    <button
      className={className}
      onClick={() => {
        if (isAdded) window.location.href = getLocaleUrl(locale, "cart");
        addItem(id, 1);
      }}
    >
      {isAdded ? addedToCart : children}
    </button>
  );
};
